/* eslint-disable react/jsx-filename-extension */
import React from "react"
import { Link } from "gatsby"
import * as footerStyles from "./footer.module.css"
import logo from "../images/logo.svg"

export default function Footer() {
  const date = new Date()

  return (
    <div className={footerStyles.container}>
      <div className={footerStyles.colContainer}>
        <div className={footerStyles.col1}>
          <div className={footerStyles.logo}>
            <Link to="/">
              <img
                className={footerStyles.logoImg}
                src={logo}
                alt="Tintex"
                draggable={false}
              />
            </Link>
          </div>
          <div className={footerStyles.footerContact}>
            <span className={footerStyles.h5}>EMAIL</span>
            <a href="mailto:info@tintex.co.uk">
              <p>info@tintex.co.uk</p>
            </a>
          </div>
          <div className={footerStyles.footerContact}>
            <span className={footerStyles.h5}>PHONE</span>
            <a href="tel:+447479555325">
              <p>07479 555 325</p>
            </a>
          </div>
        </div>
        <div className={footerStyles.col2}>
          <span className={footerStyles.colTitle}>COMPANY</span>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about/">About Us</Link>
            </li>
            <li>
              <Link to="/services/">Services</Link>
            </li>
            <li>
              <Link to="/portfolio/">Portfolio</Link>
            </li>
            <li>
              <Link to="/reviews/">Reviews</Link>
            </li>
          </ul>
        </div>
        <div className={footerStyles.col3}>
          <span className={footerStyles.colTitle}>HELP</span>
          <ul>
            <li>
              <Link to="/contact/">Contact Us</Link>
            </li>
            <li>
              <Link to="/terms-conditions/">Terms & Cons</Link>
            </li>
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/cookies-policy/">Cookies Policy</Link>
            </li>
          </ul>
        </div>
        <div className={footerStyles.col4}>
          <span className={footerStyles.colTitle}>BLOG</span>
          <ul>
            <li>Coming Soon</li>
          </ul>
        </div>
        <div className={footerStyles.copyright}>
          <div className={footerStyles.companyNo}>
            <div>
              <span>Company Number</span>
              <br />
              09866402
            </div>
            <div>
              <span>VAT Number</span>
              <br />
              GB308978753
            </div>
          </div>
          <span>
            Copyright © {date.getFullYear().toString()} Tintex. All Rights
            Reserved <br />
          </span>
        </div>
      </div>
    </div>
  )
}
