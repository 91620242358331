import React from "react"

import * as mailingListStyles from "./mailingList.module.css"
import addToMailchimp from "gatsby-plugin-mailchimp"

export default function MailingList() {
  return (
    <div className={mailingListStyles.container}>
      <div className={mailingListStyles.textContainer}>
        <div>
          <label
            htmlFor={mailingListStyles.textInput}
            className={mailingListStyles.text}
          >
            From time to time, we send out{" "}
            <span className={mailingListStyles.highlightedText}>
              exclusive offers
            </span>{" "}
            for our clients. Be the{" "}
            <span className={mailingListStyles.highlightedText}>first</span> to
            know by subscribing to our mailing list!
          </label>
          <p className={mailingListStyles.smallPrintText}>
            (We promise not to give your email out to any third parties)
          </p>
        </div>
        <div className={mailingListStyles.signUpContainer}>
          <MailingForm />
        </div>
      </div>
    </div>
  )
}

class MailingForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: null, msg: null }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email)
    this.setState({ result: result.result, msg: result.msg })
  }
  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  render() {
    return this.state.result === "success" ? (
      <div className={mailingListStyles.successMsg}>
        <span>Success!</span>
        <p>You have sucessfully subscribed to our mailing list.</p>
      </div>
    ) : this.state.result === "error" ? (
      <div>
        <form onSubmit={this._handleSubmit} id={mailingListStyles.mailingForm}>
          <input
            type="email"
            name="email"
            autoComplete="email"
            id={mailingListStyles.textInput}
            placeholder="Enter your email*"
            onChange={this.handleChange}
          ></input>
          <input
            type="submit"
            value="SIGN UP"
            className={mailingListStyles.submitBtn}
          ></input>
        </form>
        <div className={mailingListStyles.erroMsg}>
          <span>Error</span>
          <p>{this.state.msg}</p>
        </div>
      </div>
    ) : (
      <form
        onSubmit={this._handleSubmit}
        className={mailingListStyles.mailingForm}
      >
        <input
          type="email"
          name="email"
          autoComplete="email"
          id={mailingListStyles.textInput}
          placeholder="Enter your email*"
          onChange={this.handleChange}
        ></input>
        <input
          type="submit"
          value="SIGN UP"
          className={mailingListStyles.submitBtn}
        ></input>
      </form>
    )
  }
}
