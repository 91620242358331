// extracted by mini-css-extract-plugin
export var activeLink = "footer-module--activeLink--yb+N-";
export var col1 = "footer-module--col1--ChQfY";
export var col2 = "footer-module--col2--tuMe7";
export var col3 = "footer-module--col3--0R8P5";
export var col4 = "footer-module--col4--SqptP";
export var colContainer = "footer-module--colContainer---Ywpm";
export var colTitle = "footer-module--colTitle--wIG1b";
export var companyNo = "footer-module--companyNo--7-TxT";
export var container = "footer-module--container--8eueV";
export var copyright = "footer-module--copyright--NzlVR";
export var footerContact = "footer-module--footerContact--Ohloa";
export var h5 = "footer-module--h5--N3dkH";
export var logo = "footer-module--logo--mrnDn";
export var logoImg = "footer-module--logoImg--8q5Mv";